import React from "react"
import Helmet from "react-helmet";
import Link from "gatsby-link";
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../style/markdown.css"

class SideMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {opened: false};
    }

    render() {
        const {categorys} = this.props;
        return <div className={this.state.opened?"side-menu opened":"side-menu"}>
            <div className="headings">
                {
                    Object.keys(categorys).map((key, index) => {
                        return <div key={"category_" + index}>
                            <div className="heading">
                                {key}
                            </div>
                            {
                                categorys[key].map((md, index) => {
                                    return <div key={"md_" + index} className="heading heading_link">
                                        <Link
                                            activeClassName="active"
                                            to={md.frontmatter.slug}
                                        >
                                            <span />
                                            {md.frontmatter.title}
                                        </Link>
                                    </div>

                                })
                            }
                        </div>
                    })
                }
            </div>
            <div role="button" tabIndex="0" className="side-menu-button"
                 onClick={(event)=>{
                    this.setState({opened:!this.state.opened})
                 }}
                 onKeyDown={(event) => {}}
            >
            </div>
        </div>
    }
}

export default function Template({ data, pageContext: {node,edges } }) {
    let categorys = edges.reduce((prev, edge) => {
        if (edge.node.frontmatter.category in prev) {
            prev[edge.node.frontmatter.category].push(edge.node);
        } else {
            prev[edge.node.frontmatter.category] = [];
            prev[edge.node.frontmatter.category].push(edge.node);
        }
        return prev;
    }, {});
    return (
        <Layout>
            <Helmet title={node.frontmatter.title + " - TeaTak"}/>
            <div className="markdown">
                <article className="article">
                    <h1>{node.frontmatter.title}</h1>
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{__html: node.html}}
                    />
                </article>
                <SideMenu categorys={categorys} />
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
            }
        }
    }
`